import React from "react";
import AllCollectionSection from "../components/AllCollectionSection";

function AllCollectionPage(props) {
  return (
    <AllCollectionSection />
  );
}

export default AllCollectionPage;
