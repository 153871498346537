import React from "react";

import "./HeroSection.scss";
import { Link } from "./../util/router.js";

function AboutCTA(props) {
  return (
    <div className="about-cta__wrapper">
            <section className="cta-two">
                <div className="container">
                    <div className="inner-container wow fadeInUp" data-wow-duration="1500ms">
                        <div className="row no-gutters">
                            <div className="col-lg-4">
                                <div className="cta-two__box">
                                    <div className="cta-two__icon">
                                        <i className="muzex-icon-clock"></i>
                                    </div>
                                    <div className="text-left">
                                        <a href="https://hannahrosenberg.com" className="text-muted" target="_blank">
                                            <h3>Shop Art</h3>
                                            <p className="mb-0">Art designed by me</p>
                                        </a>
                                        {/* <a href="https://hannahrosenberg.com" className="thm-btn" target="_blank">Shop Now</a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="cta-two__box">
                                    <div className="cta-two__icon">
                                        <i className="muzex-icon-location"></i>
                                    </div>
                                    <div className="text-left">
                                        <a href="https://shophannahrosenberg.com" target="_blank" className="text-white">
                                            <h3>Shop Apparel</h3>
                                            <p className="mb-0">Apparel that are Artistic</p>
                                        </a>
                                        {/* <a href="https://shophannahrosenberg.com" target="_blank" className="thm-btn">Shop Here</a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="cta-two__box">
                                    <div className="cta-two__icon">
                                        <i className="muzex-icon-ticket"></i>
                                    </div>
                                    <div className="text-left">
                                        <a href="https://instagram.com/hannahrosenberg" target="_blank" className="text-muted">
                                            <h3>Follow us</h3>
                                            <p className="mb-0">Follow us on Instagram</p>
                                        </a>
                                        {/* <a href="https://instagram.com/hannahrosenberg" target="_blank" className="thm-btn">Visit Profile</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-one">
                <div className="container">
                    <img src={require('../styles/images/hannah-port.jpg').default} alt="" className="about-one__moc" />
                    <div className="block-title">
                        <p>About Hannah</p>
                        <h3>Welcome to Hannah Rosenberg Design</h3>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <p className="about-one__highlighted-text">
                            Hannah Rosenberg has always had a love of working with her hands. She studied apparel design at Drexel University, and worked for ten years in New York City designing and developing women’s clothing.
                            </p>
                        </div>
                        <div className="col-lg-4">
                            <p>My work is made up of a wide range of materials, often using whatever is at hand. The pieces include recycled papers which are painted, cut up and collaged – often mixed with silk, thread, acrylic, graphite and ink. I look to use simple materials and enjoy the experimentation and the challenge of elevating them. The best results occur when my worlds collide, art in design and design in art.</p>
                        </div>
                        <div className="col-lg-4">
                            <p>My background is in both apparel and product design. I have designed and developed a wide variety of products ranging from apparel, accessories, bags and toys. I find that my training as a designer often informs my work, as I see possibility in materials. Behind it all I am an artist with a love of translating what I see in my mind’s eye and bringing it to life.</p>
                        </div>
                    </div>
                    <div className="row justify-content-end">
                        <div className="col-lg-8">
                            <div className="about-one__feature">
                                <h3>About Me</h3>
                                <p>Hannah Rosenberg has always had a love of working with her hands. She studied apparel design at Drexel University, and worked for ten years in New York City designing and developing women’s clothing. She pursued her artwork in her small New York apartment creating, notably, a series of miniature chair sculptures.</p>
                                <p className="mt-3">Later her career took her to California where she worked as both an apparel designer and product designer while also pursuing her artwork. She transitioned to two dimensional pieces, working in colored pencil, watercolor and eventually mixed media collage. Her later works in collage nodded back to her apparel design roots with the use of thread, stitching and fabrics mixed with recycled papers and paint. </p>
                                {/* <div className="row">
                                    <div className="col-xl-5 col-lg-6 col-md-4">
                                        <ul className="list-unstyled about-one__feature-list">
                                            <li>Western & Non-Western cultures</li>
                                            <li>Artifacts and Antiquities</li>
                                            <li>Traditional Art</li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-4">
                                        <ul className="list-unstyled about-one__feature-list">
                                            <li>Contemporary Design</li>
                                            <li>Middle Eastern Art</li>
                                            <li>War History</li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-md-4">
                                        <ul className="list-unstyled about-one__feature-list">
                                            <li>20th Century Design</li>
                                            <li>Arts of Global Africa</li>
                                            <li>Gardens</li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                            <div className="mt-5">
                                <Link to="/about" className="thm-btn">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    
  );
}

export default AboutCTA;
