import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Contact from "./Contact";

function ContactSection(props) {
  return (
    <div className="contact-one">
            <div className="container">
                <div className="block-title-two text-center pt-5">
                    <p>Contact</p>
                    <h3>Stay Touch With Us</h3>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="contact-one__box">
                            {/* <h3>Opening Hours</h3> */}
                            <p>Just fill the form and we will get back to you as soon as possible. You can contact us by the following email address as well.</p>
                        </div>
                        <div className="contact-one__box">
                            <h3>Email Address</h3>
                            {/* <p>77408 Satterfield Motorway <br/> Suite 469 New Antonetta, BC </p> */}
                            <p>
                                <a href="mailto:info@designhannahrosenberg.com">info@designhannahrosenberg.com</a>
                            </p>
                        </div>
                        <div className="contact-one__box">
                            <h3>Social Contact</h3>
                            <div className="contact-one__box-social">
                                <a href="#" className="fab fa-facebook-f"></a>
                                {/* <a href="#" className="fab fa-twitter"></a> */}
                                {/* <a href="#" className="fab fa-vimeo"></a> */}
                                <a href="#" className="fab fa-instagram"></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <Contact />
                        {/* <form className="contact-form-validated contact-one__form">
                            <div className="row">
                                <div className="col-lg-6">
                                    <input type="text" placeholder="Your Name*" />
                                </div>
                                <div className="col-lg-6">
                                    <input type="text" placeholder="Your Email*" />
                                </div>
                                <div className="col-lg-12">
                                    <input type="text" placeholder="Subject" />
                                </div>
                               
                                <div className="col-lg-12">
                                    <textarea name="message" placeholder="Your Message"></textarea>
                                </div>
                                <div className="col-lg-12">
                                    <button className="thm-btn contact-one__btn" type="submit">Submit Now</button>
                                </div>
                            </div>
                        </form> */}
                    </div>
                </div>
            </div>
        </div>
    // <Section
    //   bg={props.bg}
    //   textColor={props.textColor}
    //   size={props.size}
    //   bgImage={props.bgImage}
    //   bgImageOpacity={props.bgImageOpacity}
    // >
    //   <Container
    //     style={{
    //       maxWidth: "850px",
    //     }}
    //   >
    //     <SectionHeader
    //       title={props.title}
    //       subtitle={props.subtitle}
    //       size={2}
    //       spaced={true}
    //       className="text-center"
    //     />
    //     <Contact
    //       showNameField={props.showNameField}
    //       buttonText={props.buttonText}
    //       buttonColor={props.buttonColor}
    //     />
    //   </Container>
    // </Section>
  );
}

export default ContactSection;
