import React from "react";
import ProductSection from "../components/ProductSection";

function ProductPage(props) {
  return (
    <ProductSection />
  );
}

export default ProductPage;
