import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SectionHeader from "./SectionHeader";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import Image from "react-bootstrap/Image";
import "./HeroSection.scss";

function AboutPageSection(props) {
  return (
    <section className="about-four">
        <div className="container">
            <div className="about-four__image wow fadeInRight" data-wow-duration="1500ms">
                <img src={require('../styles/images/hannah-portrait.jpg').default} alt="" />
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="about-four__content">
                        <div className="block-title">
                            <p>Know More</p>
                            <h3>About Hannah Rosenberg</h3>
                        </div>
                        <div className="about-four__highlite-text">
                            <p>Hannah Rosenberg has always had a love of working with her hands. She studied apparel design at Drexel University, and worked for ten years in New York City designing and developing women’s clothing.</p>
                        </div>
                        <p>She pursued her artwork in her small New York apartment creating, notably, a series of miniature chair sculptures. Later her career took her to California where she worked as both an apparel designer and product designer while also pursuing her artwork.</p>
                        <p>She transitioned to two dimensional pieces, working in colored pencil, watercolor and eventually mixed media collage.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
  );
}

export default AboutPageSection;
