import React, {useState, useEffect} from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Contact from "./Contact";
import Tabletop, { init } from "tabletop";
import $ from "jquery";
import Papa from "papaparse";
import { Link } from "../util/router.js";
import jQueryBridget from 'jquery-bridget';
import Isotope from 'isotope-layout';
import { WebIdentityCredentials } from "aws-sdk";
// const Tabletop = require('tabletop');

jQueryBridget( 'isotope', Isotope, $ );
function myprop(mycollection){
    return (
        <div>
            <h2>{mycollection}</h2>
        </div>
    )
}


function all(){
        
        if ($('.post-filter').length) {
            var postFilterList = $('.post-filter li');
            // for first init
            $('.filter-layout').isotope({
                filter: '.filter-item',
                animationOptions: {
                    duration: 500,
                    easing: 'linear',
                    queue: false
                }
            });
            // on click filter links
            postFilterList.children('span').on('click', function () {
                var Self = $(this);
                var selector = Self.parent().attr('data-filter');
                postFilterList.children('span').parent().removeClass('active');
                Self.parent().addClass('active');


                $('.filter-layout').isotope({
                    filter: selector,
                    animationOptions: {
                        duration: 500,
                        easing: 'linear',
                        queue: false
                    }
                });
                return false;
            });
        }    

}
function AllCollectionSection(props) {
    const initial_collection = [{
        id: 0,
        title: '',
        short_description: '',
        long_description: '',
        image: '',
        category: ''
    }]
    const [collections, setCollections] = useState(initial_collection);
    
    useEffect(() => {
        
        console.log('started');
        Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vTGTwJS0C_yq_CHsLpakRMQ7k5WWK1YfxEXqupGOgvpFUWl03ZjtFP-sFYJs52JdthGZ0doXhA9YL_y/pub?output=csv', {
          download: true,
          header: true,
          complete: function(results) {
            var data = results.data
            console.log(data)
            setCollections(data)
          }
        })
        

        // console.log('haha', sketchs);
        // Tabletop.init( { key: '1zBF1cSVMcBMSfxQJq10eOZT-41h5mpsXWWxrl7Ir1xQ',
        // callback: function(data, tabletop) { 
        //     // sketchbooks = data;
        //     console.log(data)
        // },
        // simpleSheet: true } 
        // )
        // console.log(sketchbooks);
    }, []);
  return (
    <section className="collection-grid">
    <div className="container">
        <div className="collection-grid__top">
            <div className="block-title-two text-center pt-5">
                <p>Gallery</p>
                <h3>Explore <br/> All Collection</h3>
            </div>
            
            <ul className="collection-filter list-unstyled">
                {/* <li data-filter=".filter-item" className="active"><span>All Section</span></li>
                <li data-filter=".Sketchbook"><span>Sketchbook</span></li>
                <li data-filter=".Art"><span>Art</span></li>
                <li data-filter=".Product"><span>Product</span></li> */}
                <li className="active"><span><Link to="/collections/all">All Collection</Link></span></li>
                <li><span><Link to="/collections/sketchbook">Sketchbook</Link></span></li>
                <li><span><Link to="/collections/art">Art</Link></span></li>
                <li><span><Link to="/collections/products">Product</Link></span></li>
            </ul>
        </div>
        <div className="row high-gutter filter-layout">
            {console.log('hello')}
            
            {collections.map((item, index) => (
                
                    <div className={"col-lg-4 col-md-6 filter-item " + item.category}>
                        <div className="collection-grid__single">
                            <div className="collection-grid__image">
                                <img src={item.image} alt="" />
                                <Link to={`/portfolio/`+item.url} className="collection-grid__link">+</Link>
                            </div>
                            <div className="collection-grid__content">
                                <h3><a href="#">{item.title}</a></h3>
                                <p>{item.category}</p>
                            </div>
                        </div>
                    </div>
            ))}
        </div>
    </div>
</section>
    
  );
  
}
export default AllCollectionSection;
