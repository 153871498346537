import React from "react";

import "./HeroSection.scss";
import { Link } from "./../util/router.js";

function FeaturedCollection(props) {
  return (
    <section className="featured-collection">
    <div className="container">
        <div className="row">
            <div className="col-lg-6">
                <div className="featured-collection__left">
                    <div className="featured-collection__image">
                        <img src={require('../styles/images/featured-collection.jpg').default} alt="" />
                    </div>
                    <p>Hannah Rosenberg has always had a love of working with her hands. She studied apparel design at Drexel University, and worked for ten years in New York City designing and developing women’s clothing.</p>
                    <Link to="/collections/all" className="thm-btn featured-collection__btn">Explore Collection</Link>
                    
                </div>
            </div>
            <div className="col-lg-6">
                <div className="featured-collection__right">
                    <div className="block-title">
                        <p>Collection</p>
                        <h3>Featured Collection</h3>
                    </div>
                    <p>Welcome to Hannah Rosenberg's design collection. It includes works of art created by Hannah and the Sketchbooks.</p>
                    <div className="featured-collection__image">
                        {/* <img src="assets/images/resources/featured-collection-1-2.jpg" alt="" /> */}
                        <img src={require('../styles/images/featured-collection-2.jpg').default} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    
  );
}

export default FeaturedCollection;
