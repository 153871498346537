import React from "react";
import SinglePortfolioSection from "../components/SinglePortfolioSection";
import { useRouter } from "../util/router.js";
import { requireAuth } from "../util/auth.js";

function SinglePortfolioPage(props) {
  const router = useRouter();

  return (
    <SinglePortfolioSection
      
      url={router.query.url}
      
    />
  );
}

export default SinglePortfolioPage;
