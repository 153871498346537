import React, { useState } from "react";
import FormAlert from "./FormAlert";
import Form from "react-bootstrap/Form";
import FormField from "./FormField";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Col from "react-bootstrap/Col";
import contact from "./../util/contact.js";
import { useForm } from "react-hook-form";

function Contact(props) {
  const [pending, setPending] = useState(false);

  const [formAlert, setFormAlert] = useState(null);
  const { handleSubmit, register, errors, reset } = useForm();

  const [cname, setCName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);
    console.log(cname, email, subject, message);
    contact
      .submit({
        'name':cname, 
        'email':email,
        'subject':subject, 
        'message':message,
      })
      .then(() => {
        // Clear form
        reset();
        setCName('');
        setEmail('');
        setSubject('');
        setMessage('');
        // Show success alert message
        setFormAlert({
          type: "success",
          message: "Your message has been sent!",
        });
      })
      .catch((error) => {
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <>
      {formAlert && (
        <FormAlert type={formAlert.type} message={formAlert.message} />
      )}

      <Form onSubmit={handleSubmit(onSubmit)} className="contact-form-validated contact-one__form">
          <div className="row">
              <div className="col-lg-6">
                  <input type="text" placeholder="Your Name*" onChange={(e) => setCName(e.target.value)} />
              </div>
              <div className="col-lg-6">
                  <input type="text" placeholder="Your Email*" onChange={(e) => setEmail(e.target.value)}/>
              </div>
              <div className="col-lg-12">
                  <input type="text" placeholder="Subject" onChange={(e) => setSubject(e.target.value)}/>
              </div>
              {/* <div className="col-lg-6">
                  <select className="selectpicker">
                      <option value="#">Discuss For</option>
                      <option value="General Query">General Query</option>
                      <option value="For Ticketing">For Ticketing</option>
                      <option value="For Membership">For Membership</option>
                  </select>
              </div> */}
              <div className="col-lg-12">
                  <textarea name="message" placeholder="Your Message" onChange={(e) => setMessage(e.target.value)}></textarea>
              </div>
              <div className="col-lg-12">
              <Button
                type="submit"
                className="thm-btn contact-one__btn"
                disabled={pending}
              >
                <span>Send Message</span>

                {pending && (
                  <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden={true}
                    className="ml-2"
                  >
                    <span className="sr-only">Sending...</span>
                  </Spinner>
                )}
              </Button>
                  {/* <button className="thm-btn contact-one__btn" type="submit">Submit Now</button> */}
              </div>
          </div>
        {/* <Form.Row>
          {props.showNameField && (
            <Form.Group as={Col} xs={12} sm={6} controlId="formName">
              <FormField
                size="lg"
                name="name"
                type="text"
                placeholder="Name"
                error={errors.name}
                inputRef={register({
                  required: "Please enter your name",
                })}
              />
            </Form.Group>
          )}

          <Form.Group
            as={Col}
            xs={12}
            sm={props.showNameField ? 6 : 12}
            controlId="formEmail"
          >
            <FormField
              size="lg"
              name="email"
              type="email"
              placeholder="Email"
              error={errors.email}
              inputRef={register({
                required: "Please enter your email",
              })}
            />
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="formMessage">
          <FormField
            size="lg"
            name="message"
            type="textarea"
            placeholder="Message"
            rows={5}
            error={errors.message}
            inputRef={register({
              required: "Please enter a message",
            })}
          />
        </Form.Group> */}
        
      </Form>
    </>
  );
}

export default Contact;
