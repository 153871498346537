import React from "react";
import SketchbookSection from "../components/SketchbookSection";

function SketchbookPage(props) {
  return (
    <SketchbookSection />
  );
}

export default SketchbookPage;
