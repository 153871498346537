import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SectionHeader from "./SectionHeader";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import Image from "react-bootstrap/Image";
import "./HeroSection.scss";
import { Link } from "./../util/router.js";

function AboutHistory(props) {
  return (
    <section className="history-one">
    <img src={require('../styles/images/sculpture.png').default} className="history-one__sculp" alt="" />
    <div className="container">
        <div className="block-title-two text-center pt-5">
            <p>Design</p>
            <h3>Love for Art</h3>
        </div>
        <div className="history-one__single mb-5">
            <div className="row">
                <div className="col-lg-6">
                    <div className="history-one__image wow fadeInLeft" data-wow-duration="1500ms">
                        <img src={require('../styles/images/hannah33.jpg').default} alt="" />
                    </div>
                </div>
                <div className="col-lg-6 d-flex">
                    <div className="history-one__content my-auto">
                        <div className="history-one__top block-title mb-5">
                            <p>About</p>
                            {/* <h3>More About Hannah</h3> */}
                        </div>
                        <p>Hannah's later works in collage nodded back to her apparel design roots with the use of thread, stitching and fabrics mixed with recycled papers and paint.</p>
                        <p>Through experimentation, her work became rich with texture, color and depth, using collage in her own unique way-in more of a painterly fashion. After several years in California, she pursued work in metal sculpture again, picking up where she left off fifteen years prior, as she began to interweave metal sculpture in between her other two dimensional works.</p>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="get_in_touch_cta">
            <div className="container text-center">
                <Link to="/contact" className="thm-btn">Get in Touch With Us</Link>
            </div>
        </div>
        
    </div>
</section>
    
  );
}

export default AboutHistory;
