import React, {useEffect} from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "./../util/router.js";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import { useAuth } from "./../util/auth.js";
import { useLocation } from 'react-router-dom';
import $ from "jquery";

function dynamicCurrentMenuClass(selector) {
  let FileName = window.location.href.split('/').reverse()[0];

  selector.find('li').each(function () {
      let anchor = $(this).find('a');
      if ($(anchor).attr('href') == FileName) {
          $(this).addClass('current');
      }
  });
  // if any li has .current elmnt add class
  selector.children('li').each(function () {
      if ($(this).find('.current').length) {
          $(this).addClass('current');
      }
  });
  // if no file name return 
  if ('' == FileName) {
      selector.find('li').eq(0).addClass('current');
  }
}

function NavbarCustom(props) {
  const auth = useAuth();
  let location = useLocation();
  useEffect(() => {
      // console.log('started');
      if ($('.side-menu__toggler').length) {
          $('.side-menu__toggler').on('click', function (e) {
              $('.side-menu__block').toggleClass('active');
              e.preventDefault();
          });
      }

      if ($('.side-menu__block-overlay').length) {
          $('.side-menu__block-overlay').on('click', function (e) {
              $('.side-menu__block').removeClass('active');
              e.preventDefault();
          });
      }

      if ($('.main-nav__main-navigation').length) {
        let mobileNavContainer = $('.mobile-nav__container');
        let mainNavContent = $('.main-nav__main-navigation').html();



        mobileNavContainer.append(function () {
            return mainNavContent;
        });



        //Dropdown Button
        mobileNavContainer.find('li.dropdown .dropdown-btn').on('click', function () {
            $(this).toggleClass('open');
            $(this).prev('ul').slideToggle(500);
        });

        // dynamic current class        
        let mainNavUL = $('.main-nav__main-navigation').find('.main-nav__navigation-box');
        let mobileNavUL = mobileNavContainer.find('.main-nav__navigation-box');

        dynamicCurrentMenuClass(mainNavUL);
        dynamicCurrentMenuClass(mobileNavUL);


    }
  }, []);
  return (
    <>
    {/* <div className="topbar-one">
            <div className="container">
                <div className="topbar-one__left">
                    <p>Look at the Calender for the Upcoming Exhibitions.</p>
                </div>
                <div className="topbar-one__right">
                    <a href="#"><i className="far fa-clock"></i> Mon - Sat 9.00 - 18.00</a>
                    <a href="#"><i className="fa fa-phone-alt"></i> (251) 235-3256</a>
                    <a href="#" className="thm-btn topbar__btn">Buy Tickets</a>
                </div>
            </div>
      </div> */}
    <Navbar className="main-nav-one stricky">
            <div className="container">
                <div className="inner-container">
                    <div className="logo-box">
                        <Link to="/">
                            <img src={require('../styles/images/new_logo.png').default} alt="" width="300" />
                        </Link>
                        <a href="#" className="side-menu__toggler"><i className="muzex-icon-menu"></i></a>
                    </div>
                    <div className="main-nav__main-navigation">
                        <ul className="main-nav__navigation-box">
                            <li className={location.pathname == '/' ? 'active' : '' }>
                                <Link to="/">Home</Link>
                                {/* <ul>
                                    <li><a href="index.html">Home 01</a></li>
                                    <li><a href="index-2.html">Home 02</a></li>
                                    <li><a href="index-3.html">Home 03</a></li>
                                    <li><a href="index-rtl.html">Home RTL</a></li>
                                    <li className="dropdown">
                                        <a href="#">Header Styles</a>
                                        <ul>
                                            <li><a href="index.html">Header 01</a></li>
                                            <li><a href="index-2.html">Header 02</a></li>
                                            <li><a href="index-3.html">Header 03</a></li>
                                        </ul>
                                    </li>
                                </ul> */}
                            </li>
                            <li className={location.pathname == '/about' ? 'active' : '' }>
                                <Link to="/about">About</Link>
                                {/* <ul>
                                    <li><a href="about.html">About Us</a></li>
                                    <li><a href="team.html">Team</a></li>
                                    <li><a href="donate.html">Donate</a></li>
                                    <li><a href="venue.html">Veneu</a></li>
                                    <li><a href="faq.html">FAQ</a></li>
                                    <li><a href="membership.html">Membership</a></li>
                                </ul> */}
                            </li>
                            <li className={location.pathname == '/collections/all' ? 'active dropdown' : 'dropdown' }>
                                <Link to="/collections/all">Collections</Link>
                                <ul>
                                    {/* <li><Link to="/collections/art">Art</Link></li> */}
                                    <li><Link to="/collections/sketchbook">Sketchbook</Link></li>
                                    <li><Link to="/collections/products">Products</Link></li>
                                </ul>
                            </li>
                            {/* <li className={location.pathname == '/art' ? 'active' : '' }>
                                <Link to="/collections/art">Art</Link>
                            </li> */}
                            {/* <li className={location.pathname == '/sketchbook' ? 'active' : '' }>
                                <Link to="/collections/sketchbook">Sketchbook</Link>
                            </li> */}
                            {/* <li className="dropdown">
                                <a href="news-standard.html">News</a>
                                <ul>
                                    <li><a href="news-standard.html">News Standard</a></li>
                                    <li><a href="news-grid.html">News Grid</a></li>
                                    <li><a href="news-masonary.html">News Masonary</a></li>
                                    <li><a href="news-details.html">News Details</a></li>
                                </ul>
                            </li> */}
                            {/* <li className="dropdown">
                                <a href="news-standard.html">News</a>
                                <ul>
                                    <li><a href="news-standard.html">News Standard</a></li>
                                    <li><a href="news-grid.html">News Grid</a></li>
                                    <li><a href="news-masonary.html">News Masonary</a></li>
                                    <li><a href="news-details.html">News Details</a></li>
                                </ul>
                            </li> */}
                            <li className={location.pathname == '/contact' ? 'active' : '' }><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                    <div className="main-nav__right">
                      <Link to="/contact" className="thm-btn display-desktop">Get in Touch</Link>
                        <a href="#" className="search-popup__toggler display-mobile"><i className="muzex-icon-search"></i></a>
                        <a className="sidemenu-icon side-content__toggler display-mobile" href="#"><i className="muzex-icon-menu"></i></a>
                    </div>
                </div>
            </div>
        
      {/* <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              className="d-inline-block align-top"
              src={props.logo}
              alt="Logo"
              height="30"
            />
          </Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle aria-controls="navbar-nav" className="border-0" />
        <Navbar.Collapse id="navbar-nav" className="justify-content-end">
          <Nav>
            {auth.user && (
              <NavDropdown id="dropdown" title="Account" alignRight={true}>
                <LinkContainer to="/dashboard">
                  <NavDropdown.Item active={false}>Dashboard</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/settings/general">
                  <NavDropdown.Item active={false}>Settings</NavDropdown.Item>
                </LinkContainer>

                <Dropdown.Divider />

                <LinkContainer to="/auth/signout">
                  <NavDropdown.Item
                    active={false}
                    onClick={(e) => {
                      e.preventDefault();
                      auth.signout();
                    }}
                  >
                    Sign out
                  </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}

            {!auth.user && (
              <Nav.Item>
                <LinkContainer to="/auth/signin">
                  <Nav.Link active={false}>Sign in</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container> */}
    </Navbar>
    </>
  );
}

export default NavbarCustom;
