import React from "react";
import ArtSection from "../components/ArtSection";

function ArtPage(props) {
  return (
    <ArtSection />
  );
}

export default ArtPage;
