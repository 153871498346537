import React, {useState, useEffect} from "react";

import "./HeroSection.scss";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CircleType from 'circletype';
import $ from "jquery";
import { Link } from "./../util/router.js";
import Papa from "papaparse";

function ArtCollection(props) {
    let state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            },
        },
    }
    const initial_arts = [{
        id: 0,
        title: '',
        short_description: '',
        long_description: '',
        image: '',
        category: ''
    }]
    const [arts, setArts] = useState(initial_arts);

    useEffect(() => {
        const circleType = new CircleType(document.getElementById('curved_text'));
        // circleType.position('absolute');
        circleType.radius();
        circleType.dir(1);
        circleType.forceHeight(true);
        circleType.forceWidth(true);

        Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vTGTwJS0C_yq_CHsLpakRMQ7k5WWK1YfxEXqupGOgvpFUWl03ZjtFP-sFYJs52JdthGZ0doXhA9YL_y/pub?output=csv', {
          download: true,
          header: true,
          complete: function(results) {
            var data = results.data
            console.log(data)
            setArts(data)
          }
        });
    }, []);

    const options = {
        loop: true,
        autoplay: true,
        nav:false
    }
    
  return (
    <section className="collection-one all_collections">
    <div className="container">
        <div className="collection-one__top">
            <div className="block-title">
                <p>Gallery</p>
                <h3>Explore The Collection</h3>
            </div>
            <div className="more-post__block">
                <Link className="more-post__link" to="/collections/all">
                    View More
                    <span id="curved_text" className="curved-circle">View More &nbsp;&emsp;View More &nbsp;&emsp;View More View More View More &nbsp;&emsp;View &nbsp;&emsp; </span>
                    
                </Link>
            </div>
        </div>
    </div>

    {arts.length && (
        // <OwlCarousel className='collection-one__carousel thm__owl-carousel owl-theme owl-carousel display-desktop' loop autoplay margin={100} autoplayTimeout={5000} autoplayHoverPause smartSpeed={250} items={3} dots={false} responsive={state.responsive}>
        //     {arts.map((item, index) => (
                
        //         <div className="item">
        //             <div className="collection-one__single">
        //                 <img src={item.image} alt="" />
        //                 <div className="collection-one__content">
        //                     <h3><Link to={`/portfolio/`+item.url}>{item.title}</Link></h3>
        //                 </div>
        //             </div>
        //         </div>
        //     ))}
        
        // </OwlCarousel>
        <div className="container-fluid">
            <div className="home_featured_col">
                <div className='row high-gutter filter-layout'>
                    {arts.slice(0, 4).map((item, index) => (
                        
                        <div className={"col-lg-3 col-md-6 filter-item " + item.category}>
                            <div className="collection-grid__single">
                                <div className="collection-grid__image">
                                    <img src={item.image} alt="" />
                                    <Link to={`/portfolio/`+item.url} className="collection-grid__link">+</Link>
                                </div>
                                <div className="collection-grid__content">
                                    <h3><a href="#">{item.title}</a></h3>
                                    <p>{item.category}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                
                </div>
            </div>
        </div>
    )}
    


    <OwlCarousel className='collection-one__carousel thm__owl-carousel owl-theme owl-carousel display-mobile' loop autoplay margin={100} autoplayTimeout={5000} autoplayHoverPause smartSpeed={250} items={1} dots={false}>
        {/* {arts.map((item, index) => (
                    
            <div className="item">
                <div className="collection-one__single">
                    <img src={item.image} alt="" />
                    <div className="collection-one__content">
                        <h3><Link to={`/portfolio/`+item.url}>{item.title}</Link></h3>
                    </div>
                </div>
            </div>
        ))} */}
        <div className="item">
            <div className="collection-one__single">
                <img src={require('../styles/images/collection/art1.jpg').default} alt="" />
                <div className="collection-one__content">
                    <h3><a href="#">Of Storms and Roiling Seas</a></h3>
                </div>
            </div>
        </div>
        <div className="item">
            <div className="collection-one__single">
                <img src={require('../styles/images/collection/art2.jpg').default} alt="" />
                <div className="collection-one__content">
                    <h3><a href="#">Decision's Ghost</a></h3>
                </div>
            </div>
        </div>
        <div className="item">
            <div className="collection-one__single">
                <img src={require('../styles/images/collection/art3.jpg').default} alt="" />
                <div className="collection-one__content">
                    <h3><a href="#">Wherever You Go, There You Are</a></h3>
                </div>
            </div>
        </div>
        <div className="item">
            <div className="collection-one__single">
                <img src={require('../styles/images/collection/art4.jpg').default} alt="" />
                <div className="collection-one__content">
                    <h3><a href="event-details.html">Floating Daisies</a></h3>
                </div>
            </div>
        </div>
        <div className="item">
            <div className="collection-one__single">
                <img src={require('../styles/images/collection/art5.jpg').default} alt="" />
                <div className="collection-one__content">
                    <h3><a href="#">Gold In These Hills</a></h3>
                </div>
            </div>
        </div>
        <div className="item">
            <div className="collection-one__single">
                <img src={require('../styles/images/collection/art6.jpg').default} alt="" />
                <div className="collection-one__content">
                    <h3><a href="#">Fenced Out</a></h3>
                </div>
            </div>
        </div>

        <div className="item">
            <div className="collection-one__single">
                <img src={require('../styles/images/collection/sketch1.jpg').default} alt="" />
                <div className="collection-one__content">
                    <h3><a href="#">Ideation For Kitchen Gifts</a></h3>
                </div>
            </div>
        </div>
      
    </OwlCarousel>
</section>
    
  );
}

export default ArtCollection;
