import React, {useState, useEffect} from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Contact from "./Contact";
import Tabletop, { init } from "tabletop";
import Papa from "papaparse";
import { Link } from "./../util/router.js";
// const Tabletop = require('tabletop');


function SideMenu(props) {
    
    
    useEffect(() => {
        
        console.log('started');

        // console.log('haha', sketchs);
        // Tabletop.init( { key: '1zBF1cSVMcBMSfxQJq10eOZT-41h5mpsXWWxrl7Ir1xQ',
        // callback: function(data, tabletop) { 
        //     // sketchbooks = data;
        //     console.log(data)
        // },
        // simpleSheet: true } 
        // )
        // console.log(sketchbooks);
    }, []);
  return (
    <div className="side-menu__block">

        <a href="#" className="side-menu__toggler side-menu__close-btn"><i className="fa fa-times"></i>
            </a>

        <div className="side-menu__block-overlay custom-cursor__overlay">
            <div className="cursor"></div>
            <div className="cursor-follower"></div>
        </div>
        <div className="side-menu__block-inner ">

            <a href="index.html" className="side-menu__logo"><img src={require('../styles/images/designlogo.png').default} alt="" width="143" /></a>
            <nav className="mobile-nav__container">
                
            </nav>
            <p className="side-menu__block__copy">&copy; 2021 <a href="#">Hannah Rosenberg Design</a> - All rights reserved.</p>
            <div className="side-menu__social">
                <a href="#"><i className="fab fa-facebook-f"></i></a>
                <a href="#"><i className="fab fa-twitter"></i></a>
                <a href="#"><i className="fab fa-instagram"></i></a>
            </div>
        </div>
    </div>
    
  );
}

export default SideMenu;
