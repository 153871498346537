import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import { Link } from "./../util/router.js";
import "./Footer.scss";

function Footer(props) {
  return (
    // <footer className="site-footer">
    //         <div className="site-footer__bottom">
    //             <div className="container">
    //                 <div className="inner-container">
    //                     <p>&copy; Copyright 2020 Muzex. All Rights Reserved</p>
    //                     <a href="index.html" className="site-footer__bottom-logo">
    //                         <img src="assets/images/logo-footer-1.png" alt="" />
    //                     </a>
    //                     <div className="site-footer__bottom-links">
    //                         <a href="#">Terms & conditions</a>
    //                         <a href="#">Privacy policy & Terms of use</a>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //   </footer>
    <div
      // bg={props.bg}
      // textColor={props.textColor}
      // size={props.size}
      // bgImage={props.bgImage}
      // bgImageOpacity={props.bgImageOpacity}
      className="footer site-footer"
    >
      <div className="site-footer__bottom">
      <Container>
        <div className="FooterComponent__inner inner-container">
          <div className="social left">
            <a
              href="https://twitter.com/divjoy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-twitter" />
              </span>
            </a>
            <a
              href="https://facebook.com/DivjoyOfficial"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-facebook-f" />
              </span>
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-instagram" />
              </span>
            </a>
            {/* <Link to="/">
              <img src={require('../styles/images/designlogo.png').default} alt="" />
            </Link> */}
          </div>
          <a href="/" className="site-footer__bottom-logo">
              <img src={require('../styles/images/roundlogo.png').default} alt="" width="100"/>
          </a>
          <div className="links right">
            <Link to="/about">About</Link>
            <Link to="/collections/all">Collections</Link>
            <Link to="/contact">Contact</Link>
            {/* <a
              target="_blank"
              href="https://medium.com"
              rel="noopener noreferrer"
            >
              Blog
            </a> */}
          </div>
          </div>
          <div className="FooterComponent__inner row">
          <div className="copyright col-md-6 justify-content-start footer-copyright">© 2021 Hannah Rosenberg Design. All Rights Reserved.</div>
          <div className="social col-md-6 justify-content-end footer-copyright">
            Managed by <a href="https://embtelsolutions.com/?utm_campaign=hannahdesign&utm_medium=website&utm_source=footer" target="_blank" className="ml-2"> Embtel Solutions, Inc.</a>
            {/* <a
              href="https://twitter.com/divjoy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-twitter" />
              </span>
            </a>
            <a
              href="https://facebook.com/DivjoyOfficial"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-facebook-f" />
              </span>
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-instagram" />
              </span>
            </a> */}
          </div>
          
        </div>
      </Container>
      </div>
    </div>
  );
}

export default Footer;
