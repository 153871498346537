import React, {useState, useEffect} from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Contact from "./Contact";
import Tabletop, { init } from "tabletop";
import Papa from "papaparse";
import { Link } from "./../util/router.js";
// const Tabletop = require('tabletop');


function ArtSection(props) {
    const initial_arts = [{
        id: 0,
        title: '',
        short_description: '',
        long_description: '',
        image: '',
        category: ''
    }]
    const [arts, setArts] = useState(initial_arts);
    
    useEffect(() => {
        
        console.log('started');
        Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vTGTwJS0C_yq_CHsLpakRMQ7k5WWK1YfxEXqupGOgvpFUWl03ZjtFP-sFYJs52JdthGZ0doXhA9YL_y/pub?output=csv', {
          download: true,
          header: true,
          complete: function(results) {
            var data = results.data
            console.log(data)
            setArts(data)
          }
        })

        // console.log('haha', sketchs);
        // Tabletop.init( { key: '1zBF1cSVMcBMSfxQJq10eOZT-41h5mpsXWWxrl7Ir1xQ',
        // callback: function(data, tabletop) { 
        //     // sketchbooks = data;
        //     console.log(data)
        // },
        // simpleSheet: true } 
        // )
        // console.log(sketchbooks);
    }, []);
  return (
    <section className="collection-grid">
    <div className="container">
        <div className="collection-grid__top">
            <div className="block-title-two text-center pt-5">
                <p>Gallery</p>
                <h3>Explore Art</h3>
            </div>
            
            
            {/* <ul className="collection-filter post-filter list-unstyled">
                <li data-filter=".filter-item" className="active"><span>All Section</span></li>
                <li data-filter=".pic"><span>Pictures</span></li>
                <li data-filter=".dis"><span>Display</span></li>
                <li data-filter=".poster"><span>Poster</span></li>
                <li data-filter=".arts"><span>Modern Arts</span></li>
            </ul> */}
            <ul className="collection-filter list-unstyled">
                {/* <li data-filter=".filter-item" className="active"><span>All Section</span></li>
                <li data-filter=".Sketchbook"><span>Sketchbook</span></li>
                <li data-filter=".Art"><span>Art</span></li>
                <li data-filter=".Product"><span>Product</span></li> */}
                <li><span><Link to="/collections/all">All Collection</Link></span></li>
                <li><span><Link to="/collections/sketchbook">Sketchbook</Link></span></li>
                <li className="active"><span><Link to="/collections/art">Art</Link></span></li>
                <li><span><Link to="/collections/products">Product</Link></span></li>
            </ul>
        </div>
        <div className="row high-gutter filter-layout">
            {arts.map((item, index) => (
                    item.category == 'Art' && (
                        <div className="col-lg-4 col-md-6 filter-item pic poster">
                            <div className="collection-grid__single">
                                <div className="collection-grid__image">
                                    <img src={item.image} alt="" />
                                    <Link to={`/portfolio/`+item.url} className="collection-grid__link">+</Link>
                                </div>
                                <div className="collection-grid__content">
                                    <h3><a href="#">{item.title}</a></h3>
                                    <p>{item.category}</p>
                                </div>
                            </div>
                        </div>
                    )
                
                // <span className='indent' key={index}>{item.id}</span>
            ))}
            {/* <div className="col-lg-4 col-md-6 filter-item pic poster">
                <div className="collection-grid__single">
                    <div className="collection-grid__image">
                        <img src="assets/images/collection/collection-g-1-4.jpg" alt="" />
                        <a href="#" className="collection-grid__link">+</a>
                    </div>
                    <div className="collection-grid__content">
                        <h3><a href="#">English Landscape Painting</a></h3>
                        <p>Linda M. Dugan</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 filter-item arts">
                <div className="collection-grid__single">
                    <div className="collection-grid__image">
                        <img src="assets/images/collection/collection-g-1-5.jpg" alt="" />
                        <a href="#" className="collection-grid__link">+</a>
                    </div>
                    <div className="collection-grid__content">
                        <h3><a href="#">English Landscape Painting</a></h3>
                        <p>Linda M. Dugan</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 filter-item dis pic">
                <div className="collection-grid__single">
                    <div className="collection-grid__image">
                        <img src="assets/images/collection/collection-g-1-6.jpg" alt="" />
                        <a href="#" className="collection-grid__link">+</a>
                    </div>
                    <div className="collection-grid__content">
                        <h3><a href="#">English Landscape Painting</a></h3>
                        <p>Linda M. Dugan</p>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
</section>
    
  );
}

export default ArtSection;
