import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SectionHeader from "./SectionHeader";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import Image from "react-bootstrap/Image";
import "./HeroSection.scss";
import { Link } from "./../util/router.js";

function HeroSection(props) {
  return (
    <section className="banner-section banner-carousel">
        <div className="container">
            <div className="">
                <div className="banner-content content-box">
                    <h2>Art is Life, Life is Art.</h2>
                    <div className="mt-3 btn-box">
                      <Link to="/about" className="thm-btn btn-style-one">Learn More</Link>
                    </div>
                </div>
            </div>
        </div>        
    </section>
    // <Section
    //   bg={props.bg}
    //   textColor={props.textColor}
    //   size={props.size}
    //   bgImage={props.bgImage}
    //   bgImageOpacity={props.bgImageOpacity}
    // >
    //   <Container>
    //     <Row className="align-items-center">
    //       <Col lg={5} className="text-center text-lg-left">
    //         <SectionHeader
    //           title={props.title}
    //           subtitle={props.subtitle}
    //           size={1}
    //           spaced={true}
    //         />

    //         <LinkContainer to={props.buttonPath}>
    //           <Button variant={props.buttonColor} size="lg">
    //             {props.buttonText}
    //           </Button>
    //         </LinkContainer>
    //       </Col>
    //       <Col className="offset-lg-1 mt-5 mt-lg-0 ">
    //         <figure className="HeroSection__image-container mx-auto">
    //           <Image src={props.image} fluid={true} />
    //         </figure>
    //       </Col>
    //     </Row>
    //   </Container>
    // </Section>
  );
}

export default HeroSection;
