import React, { useState, useEffect } from "react";
import Section from "./Section";
import ReauthModal from "./ReauthModal";
import SettingsNav from "./SettingsNav";
import Container from "react-bootstrap/Container";
import FormAlert from "./FormAlert";
import SettingsGeneral from "./SettingsGeneral";
import SettingsPassword from "./SettingsPassword";
import SettingsBilling from "./SettingsBilling";
import { useAuth } from "../util/auth.js";
import Papa from "papaparse";
import { Link } from "./../util/router.js";

function SinglePortfolioSection(props) {
  

  const url = props.url;
  const initial_port = [{
    id: 0,
    title: '',
    short_description: '',
    long_description: '',
    image: '',
    category: ''
  }]
  const [portfolio, setPortfolio] = useState(initial_port);
  useEffect(() => {
    Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vTGTwJS0C_yq_CHsLpakRMQ7k5WWK1YfxEXqupGOgvpFUWl03ZjtFP-sFYJs52JdthGZ0doXhA9YL_y/pub?output=csv', {
        download: true,
        header: true,
        complete: function(results) {
          var data = results.data
          console.log(data)
          setPortfolio(data)
        }
      })
  });

  return (
    <section className="event-details pt-5">
            <div className="container">
              {portfolio.map((item, index) => (
                    item.url == url && (
                      <>
                      <div className="event-details__top">
                          <p>{item.category}</p>
                          <h3>{item.title}</h3>
                      </div>
                      <div className="row high-gutter">
                          <div className="col-lg-8">
                              <div className="portfolio_details">
                                <div className="event-details__main">
                                    <div className="event-details__image">
                                        <img src={item.image} alt="" />
                                    </div>
                                    <div className="event-details__content">
                                      <p>{item.long_description}</p>
                                        
                                    </div>
                                </div>
                                {/* <div className="event-details__post-nav">
                                    <div className="event-details__post__prev">
                                        <p>Prev Event</p>
                                        <h3><a href="#">Intern Insights Tour</a></h3>
                                    </div>
                                    <div className="event-details__post__next">
                                        <p>Next Event</p>
                                        <h3><a href="#">Calvert Richard Jones’s Duomo.</a></h3>
                                    </div>
                                </div> */}
                              </div>
                          </div>
                          <div className="col-lg-4">
                              <div className="hannah_bio">
                                  <div className="image">
                                      <img src={require('../styles/images/hannah4.jpg').default} />
                                  </div>
                                  <div className="button">
                                      <Link to="/contact" className="thm-btn">Contact Us</Link>
                                  </div>
                                  <div className="info">
                                      <p>Hannah Rosenberg has always had a love of working with her hands. She studied apparel design at Drexel University, and worked for ten years in New York City designing and developing women’s clothing. </p>
                                      <p>She pursued her artwork in her small New York apartment creating, notably, a series of miniature chair sculptures. Later her career took her to California where she worked as both an apparel designer and product designer while also pursuing her artwork.</p>
                                      <p>She transitioned to two dimensional pieces, working in colored pencil, watercolor and eventually mixed media collage. Her later works in collage nodded back to her apparel design roots with the use of thread, stitching and fabrics mixed with recycled papers and paint. </p>
                                  </div>
                                  <div className="socials">
                                    <div className="contact-one__box-social text-center">
                                        <a href="#" className="fab fa-facebook-f"></a>
                                        {/* <a href="#" className="fab fa-twitter"></a> */}
                                        {/* <a href="#" className="fab fa-vimeo"></a> */}
                                        <a href="#" className="fab fa-instagram"></a>
                                    </div>
                                  </div>
                              </div>
                              {/* <div className="event-details__booking">
                                  <ul className="event-details__booking-info list-unstyled">
                                      <li>
                                          <span>Type:</span>
                                          Art Gallery
                                      </li>
                                      <li>
                                          <span>Date:</span>
                                          Wednesday, Dec 18, 2019
                                      </li>
                                      <li>
                                          <span>Time:</span>
                                          12.00AM - 4.00PM
                                      </li>
                                      <li>
                                          <span>Venue:</span>
                                          <i className="fa fa-map-marker"></i> 1 West 52 Street, Mangatha
                                      </li>
                                      <li>
                                          <span>Cost:</span>
                                          $50/Person
                                      </li>
                                  </ul>
                                  <a href="#" className="thm-btn event-details__book-btn">Book Ticket</a>
                                  
                              </div> */}
                          </div>
                      </div>
                      </>
                    )
            ))}
            </div>
        </section>
  );
}

export default SinglePortfolioSection;
